import Vue from 'vue'
// import login from './login.vue'
// import { Button, Select } from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';

import App from './App'
import router from './router'


import request from '@/utils/request';
Vue.prototype.request=request

 Vue.use(ElementUI)


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
